import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Chip, Stack } from '@mui/material';


const Details = ({
  surveyName,
  audienceData,
  segmentData,
  questionsData,
  hasSegmentData,
  selectSteps,
  errorText,
  hasCustomSplit
}) => {

  const segmentSizeTotal = segmentData.reduce((total, field) => {
    return total + (field.segment_size ? parseInt(field.segment_size, 10) : 0);
  }, 0);

  const hasQuestionFieldNonEmptyValue = questionsData.some(textField => textField.question_text !== '');

  let questionTypeCounts = {};
  // loop through the questions and get totals for their question_type values
  questionsData.forEach(question => {
    if (questionTypeCounts[question.question_type] === undefined) {
      questionTypeCounts[question.question_type] = 1;
    } else {
      questionTypeCounts[question.question_type] += 1;
    }
  });

  const ErrorAlert = ({ errorText }) => {
    if (!errorText) {
      return;
    }

    return (
      <Alert severity="error" style={{ marginBottom: "20px", borderRadius: "20px" }}>
        <AlertTitle>Error</AlertTitle>
        {errorText}
      </Alert>
    );
  }

  return (
    <>
      <ErrorAlert errorText={errorText} />

      <div className='report-preview'>
        <div className='report-preview-content'>
          <h3>Report preview:</h3>
          <h2>{surveyName}</h2>
          <h4>Your report is ready to run</h4>
          <p>Please review your report below before running </p>

          <div className='review-block'>
            <div className='review-col col-3'>
              <h4>Target audience</h4>
              <h4>Respondents</h4>
              <a tabIndex={0} style={{ cursor: 'pointer' }} onClick={() => selectSteps(0)} >Edit</a>
            </div>
            {
              audienceData.map(audienceRecord =>
                audienceRecord.audience.trim() !== '' && (
                  <div className='review-col col-3' key={audienceRecord.id}>
                    <span>
                      {audienceRecord.audience}&nbsp;
                    </span>
                    {audienceData[0].audience_size !== 0 ? (
                    <span>
                      {hasCustomSplit ? 'Custom Distribution' : audienceRecord.audience_size + ' Respondents'}
                    </span>
                    ) : (
                    <span></span>
                    )}
                    <span></span>
                  </div>
                )
              )
            }
            {hasSegmentData && (
            <div className='review-col  col-3'>
              <h4>Segments</h4>
              <a tabIndex={0} style={{ cursor: 'pointer' }} onClick={() => selectSteps(0)} > Edit</a>
            </div>
            )}
            {hasSegmentData && (
                <>
                  {segmentData.map((x) =>
                    x.segment_chips.length !== 0 ? (
                      <div className='review-col col-3' key={x.id}>
                        <span>
                          {x.segment_chips.map((chip, index) => {
                            return <Chip
                                      className="details-chip"
                                      key={index}
                                      label={chip}/>
                          })}
                        </span>
                        <span>{x.segment_size}{(hasCustomSplit || audienceData[0].audience_size === 0) ? '' : ` / ${audienceData[0].audience_size} Respondents`}</span>
                        <span></span>
                      </div>
                    ) : (
                      <div className='review-col col-3' key={x.id}>
                        <span>{x.segment_name}</span>
                        <span>{x.segment_size}</span>
                        <span></span>
                      </div>
                    )
                  )}
                  <div className='review-col col-3 last-child'>
                    <span><strong>Total Segments: {segmentData.length}</strong></span>
                    <span><strong>Total Respondents: {segmentSizeTotal}</strong></span>
                    <span></span>
                  </div>
                </>
              )
            }
            <div className='review-col col-3'>
              <h4>Questions</h4>
              <h4>Type</h4>
              <h4>Options<a tabIndex={0} style={{ cursor: 'pointer', float: 'right' }} onClick={() => selectSteps(1)} >Edit</a></h4>
            </div>
            {
              hasQuestionFieldNonEmptyValue && (
                <>
                  {questionsData.map((x) =>
                    x.question_text && x.question_text !== '' ? (
                      <div className='review-col col-3' key={x.id}>
                        <span>{x.question_text}</span>
                        <span className="question-type">{x.question_type}</span>
                        { x.options && x.options.length !== 0 ? (
                          <span>{x.options.join(', ')}</span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    ) : null
                  )}
                </>
              )
            }
            <div className='review-col col-3'>
                <span><strong>Total Questions: {questionsData.length}</strong></span>
                <span>
                  <ul className="question-type-counts">
                  {
                    Object.entries(questionTypeCounts).map(([key, value]) => (
                      <li key={key}>{key}: {value}</li>
                    ))
                  }
                  </ul>
                </span>
                <span></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
