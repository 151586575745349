
import Loading from '../ui/Loading';
import BarChart from '../charts/BarChart';
import BarChartStacked from '../charts/BarChartStacked';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import download from '../../assets/download-icon.svg';
import { Button } from '@mui/material';
import { useApi } from '../../AuthProvider';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Unstable_Grid2';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';

const Result = () => {
  const [surveyRunResults, setSurveyRunResults] = useState(null);
  const params = useParams();
  const api = useApi();
  const [copySnackbarOpen, setCopySnackbarOpenOpen] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      let surveyRunsResponse = await api.get(`/surveys/runs/${params.surveyRunId}`);
      let surveyStatusResponse = await api.get(`/surveys/runs/${params.surveyRunId}/status`); 

      // sanitize surveyRunResults to support backwards compatibility with old survey results
      surveyRunsResponse.data.questions.forEach(question => {
        question.question_type = question.question_type.toLowerCase();
      });
      setSurveyRunResults(surveyRunsResponse.data);
      setSurveyStatus(surveyStatusResponse.data.status);
    }
    if (surveyRunResults === null && api) {
      fetchData();
    }
  }, [api]);

  const downloadSummary = (surveyRunId, summary) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([summary], {
        type: 'text/markdown',
        encoding: 'UTF-8'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${surveyRunId}.md`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const downloadFile = async (surveyRunId) => {
    var downloadResponse = await api.get(`/surveys/runs/${surveyRunId}/download/xlsx`, { responseType: "blob" });
    if (downloadResponse) {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([downloadResponse.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyRunResults.name}.xlsx`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  const QuestionResultDetails = ({ question, stackedSeries = [], answers, segments }) => {
    const [numShow, setNumShow] = useState(5);
    const [textShow, setTextShow] = useState('Show more');
    if (question.question_type === 'short response' || question.question_type === 'long response') {
      let questionAnswers = answers.filter(answer => answer.question_id === question.question_id);
      return (
        <>
          {/* if length answers is less than 5 */}
          {
            (questionAnswers.length <= 5)
            &&
            questionAnswers.map((answer, index) => (
              <div key={index}>
                <p>{answer.answer}</p>
              </div>
            ))
          }
          {/* if length answers is greater than 5 */}
          {
            (questionAnswers.length > 5)
            &&
            <div>
              {questionAnswers.slice(0, numShow).map((answer, index) => (
                <div key={index}>
                  <p>&quot;{answer.answer}&quot;</p>
                </div>
              ))}
              <Button
                onClick={() => {
                  if (numShow === 5) {
                    setNumShow(questionAnswers.length);
                    setTextShow('Show less');
                  }
                  else {
                    setNumShow(5);
                    setTextShow('Show more');
                  }
                }}
              >{textShow}</Button>
            </div>
          }
        </>
      );
    } else if (question.question_type === 'multiple choice' || question.question_type === 'single choice' || question.question_type === 'numeric rating') {
      let labels = segments.map(segment => segment.segment_chips?.length > 0 ? segment.segment_chips.join(' ') : segment.segment_name);
      const questionAnswers = answers.filter(answer => answer.question_id === question.question_id);
      console.log('**** questionType:', question.question_type);
      let segmentChartData = [];// this is an array of objects, one for each segment, to hold the segment's label and rawCounts for each option
      let stackedSeries = [];// this is the array of option-based data for the stacked bar chart
      segments.forEach(segment => {
        let segmentEntry = {
          label: segment.segment_chips?.length > 0 ? segment.segment_chips.join(' ') : segment.segment_name,
          id: segment.audience_segment_id,
          rawCounts: {},
          percentages: {},
          totalOptions: 0,
          size: segment.segment_size
        }
        // set rawCount and percentage placeholders using lowercase values initially
        question.options.forEach(option => {
          segmentEntry.rawCounts[option] = 0;
          segmentEntry.percentages[option] = '0';
        });
        segmentChartData.push(segmentEntry);
      });
      let splitOptions = [];// array of arrays, each containing obj w/ original option and split chunks

      // sometimes the question options are split on commas when they are returned in answer_array, so we need to
      // look at the questions and find any that contain commas. If so, we need to split that option on commas to generate
      // the same structure as the answer_array (when it splits), and then look for those split chunks in the answer_array
      // and if we find them ALL in the answer_array, we can increment the rawCounts for that option. This is costly but eliminates
      // the need to check for splitting on comma-only and comma-space as well as unsplit options
      if (question.options.some(option => option.includes(','))) {
        question.options.forEach(option => {
          if (option.includes(',')) {
            // console.log('found an option containing commas')
            let entry = {
              original: option,
              split: option.split(',')
            };
            // entry.split.forEach(chunk => {
            //   chunk.toLowerCase();
            // });
            splitOptions.push(entry);
            // console.log('splitOptions:', splitOptions);
          }
        });
      }
      console.log('splitOptions:', question.question_text, splitOptions);
      questionAnswers.forEach(answer => {
        let segment = segmentChartData.find(segment => segment.id === answer.audience_segment_id);

        answer.answer_array.forEach(option => {
          let lcOptionIndex = question.options.findIndex(qOption => qOption.toLowerCase() === option.toLowerCase());
          let perOptionIndex = question.options.findIndex(qOption => qOption.toLowerCase() === option.toLowerCase() + '.');
          let perQuestionIndex = question.options.findIndex(qOption => qOption[qOption.length - 1] === '.');

          if (question.question_type !== 'numeric rating') {
            // fix for when a single-choice response has been split on a comma
            // if (question.question_type === 'single choice' && answer.answer_array.length > 1) {
            //   answer.answer_array = [answer.answer_array.join(', ')];
            // }

            // if single/mult choice, we want the comparison to be between the lowercase version of the original option
            // and the lowercase version of the answer_array item
            if (lcOptionIndex > -1) {
              // direct match found with lowercase comparison
              option = question.options[lcOptionIndex];
              segment.rawCounts[option] += 1;
            } else if (perOptionIndex > -1) {
              // if a direct match is not found, check if the response is a variation of an option with/without a period "." at the end
                // indirect match found, response has added a "."
                option = question.options[perOptionIndex];
                segment.rawCounts[option] += 1;
            } else if (perQuestionIndex > -1) {
                question.options.forEach(qOption => {
                  if (qOption.endsWith('.')) {
                    let qOptionNoPeriod = qOption.slice(0, -1);
                    if (qOptionNoPeriod.toLowerCase() === option.toLowerCase()) {
                      // indirect match found, response has removed a "."
                      option = qOption;
                      segment.rawCounts[option] += 1;
                    }
                  }
                });
              } else if (splitOptions.length > 0) {
                // the only remaining variation to check after capitalization and "." removal is to check for a comma-containing
                // option that has been split into chunks in the answer_array
                  splitOptions.forEach(splitOption => {
                    let optionIsChunk = splitOption.split.includes(option);
                    if (optionIsChunk) {
                      // for each option, check to see if all the chunks included in its split array are found in the answer_array
                      let allChunksFound = splitOption.split.every(chunk => answer.answer_array.includes(chunk));
                      if (allChunksFound) {
                        // indirect match found through split concatenation
                        segment.rawCounts[splitOption.original] += 1;
                        // remove the chunks from the answer_array so they are not counted again
                        splitOption.split.forEach(chunk => {
                          let chunkIndex = answer.answer_array.findIndex(answerChunk => answerChunk === chunk);
                          if (chunkIndex > -1) {
                            answer.answer_array.splice(chunkIndex, 1);
                          }
                        });
                        // decrement the totalOptions count for this segment
                        // segment.totalOptions -= splitOption.split.length - 1;
                      }
                    }
                  });
                }
            } else {
            // numeric rating question
            if (segment.rawCounts[option] === undefined) {
              segment.rawCounts[option] = 1;
            } else {
              segment.rawCounts[option] += 1;
            }
          }

          // now increment the totalOptions for this segment
          segment.totalOptions += 1;
        });
      });
      console.log('segmentChartData:', segmentChartData);
      // calculate the percentages for each option
      segmentChartData.forEach(segment => {
        Object.keys(segment.rawCounts).forEach(option => {
          segment.percentages[option] = ((segment.rawCounts[option] / segment.size) * 100 || 0).toFixed(1);
        });
      });
      // now we create the stackSeries items for the stacked bar chart
      // TODO: consolidate these within the bounds of good taste
      if (question.question_type === 'numeric rating') {
        // this is a numeric rating question, so we use min/max values for the options
        // but we need to make sure there are no non-parseable values in the answers
        let tempAnswers = questionAnswers.filter(answer => !isNaN(parseInt(answer.answer)));
        let min = Math.min(...tempAnswers.map(answer => parseInt(answer.answer)));
        let max = Math.max(...tempAnswers.map(answer => parseInt(answer.answer)));
        min === 0 ? min = min : min = 1;
        // creates the labels for a numeric chart
        let choices = Array.from({ length: max }, (_, i) => i + 1).toString().split(',');
        choices.forEach((label, index) => {
          //built the series entry
          let seriesEntry = {
            label: label,
            data: [],
            stack: 'total',
            valueFormatter: (value, { dataIndex }) => `${segmentChartData[dataIndex].rawCounts[label] || 0} (${segmentChartData[dataIndex].percentages[label] || 0}%)`
          }
          stackedSeries.push(seriesEntry);
        });
        // now we populate the data for each option in the stackedSeries
        stackedSeries.forEach(series => {
          // append the percentage value for each option in each segment to the series.data array
          segmentChartData.forEach(segment => {
            const count = segment.percentages[series.label] || 0;
            series.data.push(count);
          });
        });
      } else if (question.question_type === 'single choice') {
        // this is a single choice question
        question.options.forEach(option => {
          let seriesEntry = {
            label: option,
            data: [],
            stack: 'total',
            valueFormatter: (value, { dataIndex }) => `${segmentChartData[dataIndex].rawCounts[option] || 0} (${segmentChartData[dataIndex].percentages[option] || 0}%)`
          }
          stackedSeries.push(seriesEntry);
        });
        // now we populate the data for each option in the stackedSeries
        stackedSeries.forEach(series => {
          // append the percentage value for each option in each segment to the series.data array
          segmentChartData.forEach(segment => {
            const count = segment.percentages[series.label] || 0;
            series.data.push(count);
          });
        });
      } else {
        ///////// this is a multiple choice question
        // for single choice and numeric rating, the responses always return a single value from each respondent
        // so the percentage value for # of time option was chosen / total options chosen is
        // the same as "percent of answers that include this response". This is **not** true for mult choice,
        // because the same respondent can choose multiple options, so the percentage of answers that include
        // this response is not the same as the percentage of times this response was chosen. THUS we need a different calculation
        // to ensure rawCounts represent number of responses that include this option
        question.options.forEach(option => {
          let seriesEntry = {
            label: option,
            data: [],
            valueFormatter: (value, { dataIndex }) => `${segmentChartData[dataIndex].rawCounts[option] || 0} (${segmentChartData[dataIndex].percentages[option] || 0}%)`
          }
          stackedSeries.push(seriesEntry);
        });
        // now we populate the data for each option in the stackedSeries
        stackedSeries.forEach(series => {
          // append the rawCounts for each option in each segment to the series.data array
          segmentChartData.forEach(segment => {
            const count = Math.floor((segment.rawCounts[series.label] / segment.size) * 100) || 0;
            series.data.push(count);
          });
        });
      }
      console.log('finished building chart, stackedSeries: ', stackedSeries);
      return (
        <BarChartStacked
          type={question.question_type}
          stackedSeries={stackedSeries}
          labels={labels}/>
      );
    }
  }

  const QuestionAudienceSegmentResults = ({ question, questionNo, segments, segment, answers }) => {

    return (
        <Box className="question-results-details">
          <p className="chart-header-question-type">{question.question_type}</p>
          <h4 className="chart-header-question-text">"{question.question_text}"</h4>
          <QuestionResultDetails question={question} segments={segments} answers={answers} />
        </Box>
    );
  }

  const QuestionResult = ({ audience, questionNo, question, segments, answers }) => {
    // if there are no segments, convert the audience into a segment-like data object
    if (segments.length === 0) {
      segments = [{
        segment_name: audience.audience,
        segment_chips: [audience.audience],
        segment_size: audience.audience_size,
        audience_segment_id: null
      }];
    }
    return (
      <>
        {segments.length > 0 && (
          <QuestionAudienceSegmentResults questionNo={questionNo} question={question} segments={segments} answers={answers} />
        )}
        {segments.length === 0 && (
            <p>No segments found</p>
        )}
      </>
    );
  }
  const SurveySummary = ({ summary }) => {
    if (summary === null || summary === undefined || (typeof summary === 'object' && Object.keys(summary).length === 0)) {
      return (
        <div className='summary-block'>
          <Alert severity="warning">
            No summary found for this survey, please contact Crowdwave support for more information
          </Alert>
          <p></p>
        </div>
      );
    } else if (typeof summary === 'string') {
      // If the summary is a string (the old way), render it as markdown
      return (
        <>
        <Markdown className={'summary-block'}>{summary}</Markdown>
        </>
      );
    // If the summary is an object (the new way), render it as HTML
    } else if (typeof summary === 'object') {
      // detect if the summary block contains any markdown syntax, and if so, hide the block
      let summaryBlocks = Object.values(summary);

      summaryBlocks.forEach((value) => {
        if (value.includes('##') || value.includes('**')) {
          // remove it from the list
          summaryBlocks = summaryBlocks.filter((item) => item !== value);
        }
      });
      return (
        <div id="synthesis-div">
          {summaryBlocks.map((value, index) => (
            <SimpleCard key={index}>
                <div className={'summary-block'} dangerouslySetInnerHTML={{ __html: value }}></div>
            </SimpleCard>
          ))}
        </div>
    )
    // If the summary is neither a string nor an object, render it as is
    } else {
      return (
        {summary}
      );
    }
  }

  const QuestionResults = ({ audience, questions, segments, answers }) => {
    return (
      <>
        {questions.map((question, index) => (
          <QuestionResult key={index} questionNo={index + 1} audience={audience} question={question} segments={segments} answers={answers} />
        ))}
      </>
    );
  }

  const copySummary = () => {
    let summary = surveyRunResults.summary;

    if (typeof summary === 'object') {
      // combine all the summary sections into one string
      summary = Object.values(summary).join('\n\n');
      const regex = /(<([^>]+)>)/gi;
      summary = summary.replace(regex, "");
    } else if (typeof summary === 'string') {
      // strip markdown
      summary = summary.replace(/(#)+ /g, '')// headers
        .replace(/\*/g, '')// bold
        .replace(/- /g, '\n');// lists
    }

    navigator.clipboard.writeText(summary);

    setCopySnackbarOpenOpen(true);
  }

  const SimpleCard = ({ title, content, children }) => {
    let cardClass = 'simple-synthesis-block';
    const headerTags = ["<h1>", "<h2>", "<h3>", "<h4>"];
    const isExpanded = headerTags.some(el => content?.includes(el)) || headerTags.some(el => children?.props.dangerouslySetInnerHTML.__html.includes(el));
    if (isExpanded) {
      cardClass = 'expanded-synthesis-block';
    }

    return (
      <Card className={`simple-card ${cardClass}`} sx={12}>
        <CardContent>
          <span className="card-title">{ title?.length > 0 && <h3>{ title }</h3> }</span>
          <span className="card-content">
            { cardClass === 'simple-synthesis-block' && <QueryStatsIcon color="primary" sx={{ position: 'relative', top: '7px', mr: 1, height: 32, width: 32, display: 'inline-block' }}/> }
            { content }
            { children }
          </span>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    );
  }

  const [visibleSegments, setVisibleSegments] = React.useState(5);
  const [visibleQuestions, setVisibleQuestions] = React.useState(5);

  const ReportLayout = () => {
    return <Box className='left-panel results-block design-b' style={{ width: 'auto' }}>
    <Grid container spacing={2}>
      <Grid className="report-controls" item xs={12}>
        <Grid className="report-status" item xs={4}>
          <span className={`${surveyStatus} status`}>{surveyStatus === 'running' ? 'In Progress' : surveyStatus}</span>
        </Grid>
        <Grid className="report-actions btnblock" item xs={8}>
          { surveyStatus === 'draft' &&
            <Button
              variant="contained"
              color="primary">
              Continue Editing
            </Button>
          }
          {/* { surveyStatus === 'complete' &&
          <Button
              variant="contained"
              color="primary"
              onClick={() => downloadFile(params.surveyRunId)}>
              Download Results
            </Button>
            } */}
          {/* <Button
            variant="outlined"
            color="primary"
            onClick={() => {navigate(`/copy-survey/${surveyRunResults.survey_id}`)}}>
              Duplicate
          </Button>
          <Button variant="outlined" color="error">Delete</Button> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h1>{surveyRunResults.name}</h1>
      </Grid>
      <Grid item xs={12} className="report-stats">
        <Grid className="stats-group" item xs={8}>
          <Box className="stats-count">
            <Box className="stat">
              <p className="stat-value">{surveyRunResults.total_respondents || 0}</p>
              <p className="stat-label">Respondents</p>
            </Box>
          </Box>
          <Box className="stats-list">
            <p className="stats-list-title">Audience and Segments</p>
            <p className="stats-list-audience">{ surveyRunResults.audiences[0].audience }</p>
              {surveyRunResults.audiences[0].segments.map((segment, index) => (
                <Box sx={{ display: index > visibleSegments-1 ? 'none' : 'block'}}>
                  <p className="segment-label">Segment {index + 1} ({ segment.segment_size } Respondents)</p>
                  <Stack key={index} direction="row" spacing={1}>
                    {segment.segment_chips.length > 0 && segment.segment_chips.map((chip, index) => (
                      <Chip className="chip segment-name" label={ chip } />
                    ))}
                    {segment.segment_chips.length === 0 && segment.segment_name && <Chip className="chip segment-name" label={ segment.segment_name } />}
                    <p className="segment-size"></p>
                  </Stack>
                </Box>
              ))
              }
              {surveyRunResults.audiences[0].segments.length > 5 &&
                <Link onClick={() => setVisibleSegments(visibleSegments === 5 ? surveyRunResults.audiences[0].segments.length : 5)}>{ visibleSegments === 5 ? 'Show More' : 'Show Less' }</Link>
              }
          </Box>
        </Grid>
        <Grid className="stats-group" item xs={8}>
          <Box className="stats-count">
            <Box className="stat">
              <p className="stat-value">{surveyRunResults.questions.length || 0}</p>
              <p className="stat-label">Questions</p>
            </Box>
          </Box>
          <Box className="stats-list">
            <p className="stats-list-title">Questions</p>
            {surveyRunResults.questions.map((question, index) => (
              <Box className="question-list-item" sx={{ display: index > visibleQuestions-1 ? 'none' : 'block'}} >
                <p className="question-list-type">({ question.question_type })</p>
                <p className="question-list-text">{ question.question_text }</p>
                  {question.options?.map((option, index) => (
                    <p className="question-list-option">{ question.options[index] }</p>
                  ))
                  }
              </Box>
            ))}
            {surveyRunResults.questions.length > 5 &&
              <Link onClick={() => setVisibleQuestions(visibleQuestions === 5 ? surveyRunResults.questions.length : 5)}>{ visibleQuestions === 5 ? 'Show More' : 'Show Less' }</Link>
            }
          </Box>
        </Grid>
      </Grid>
      {/* <Grid item xs={4} style={{ textAlign: 'right' }}>
        <a className='results-copy' onClick={() => copySummary()} style={{
          color: '#0D49A0',
          textDecoration: 'none',
          cursor: 'pointer'
        }}><ContentCopy style={{ height: "17px" }} /> Copy Summary</a>
      </Grid> */}
    </Grid>
    <SurveySummary summary={surveyRunResults.summary} />
    <div className='review-block'>
      <div className='result-header'>
        <h1>Survey Results</h1>
        <a tabIndex={0} onClick={() => downloadFile(params.surveyRunId)} className='download'><em><img src={download} alt="download" /></em>Download Results</a>
      </div>
      <div className='results-row'>
        <div className='results-col'>
          <ul>
            <li>
              Total responses
              <h2>{surveyRunResults.total_respondents || 0}</h2>
            </li>
            <li>
              Total questions
              <h2>{surveyRunResults.questions.length}</h2>
            </li>
            <li>
              Audience segments
              <h2>{surveyRunResults.audiences[0].segments.length}</h2>
            </li>
          </ul>
          <h2>Audience</h2>
          <h4>{surveyRunResults.audiences[0].audience}</h4>
        </div>
      </div>
      <div className='results-row'>
        <h2>Questions</h2>
        <QuestionResults audience={surveyRunResults.audiences[0]} questions={surveyRunResults.questions} segments={surveyRunResults.audiences[0].segments} answers={surveyRunResults.answers} />
      </div>
    </div>
  </Box>
  }
//////// end Design A/B testing ////////

  if (surveyRunResults === null) {
    return <Loading />;
  }
  process.env.REACT_APP_ENV === 'staging' && console.log('rendering survey run results...');
  process.env.REACT_APP_ENV === 'staging' && console.dir(surveyRunResults);
  return (
    <div className="dashboard">
      <div className="dashboard-content">
      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={5000}
        onClose={(event, reason) => {
          setCopySnackbarOpenOpen(false);
        }}
        message="Copied to clipboard successfully."
        className={'copy-snackbar'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <ReportLayout />
      </div>
    </div>
  );
};

export default Result;
