import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DataGridPro, useGridApiContext, useGridApiRef, GridCellEditStopReasons } from '@mui/x-data-grid-pro';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
// Actions
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import BarChartIcon from '@mui/icons-material/BarChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import Loading from '../ui/Loading';
import CustomTooltip from '../ui/CustomTooltip';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../AuthProvider';
import logoBlueSmall from '../../assets/Logo_Blue_small.svg';
import Chip from '@mui/material/Chip';
import { Avatar } from '@mui/material';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

////////////////////////////////////////
// multi-line / dynamic editing cell component //
////////////////////////////////////////
function isKeyboardEvent(event) {
  return !!event.key;
}

function DynamicInput(props) {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState();
  const [inputRef, setInputRef] = React.useState(null);
  const apiRef = useGridApiContext();

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event,
      );
    },
    [apiRef, field, id],
  );
  if (props.field === 'question_type') {
    return (
        <Select sx={{ flexGrow: 2 }} value={valueState} onChange={handleChange}>
          <MenuItem value="short response">Short Response</MenuItem>
          <MenuItem value="single choice">Single Choice</MenuItem>
          <MenuItem value="multiple choice">Multiple Choice</MenuItem>
          <MenuItem value="numeric rating">Numeric Rating</MenuItem>
        </Select>
    );
  } else {
    return (
      <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: 'block',
            position: 'absolute',
            top: 0,
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
              <InputBase
                multiline
                rows={4}
                value={valueState}
                sx={{ textarea: { resize: 'both' }, width: '100%' }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
              />
            </Paper>
          </Popper>
        )}
      </div>
    );
  }
}

const dynamicInputColumn = {
  type: 'string',
  renderEditCell: (params, valueState, ) => {
    console.log('edit cell params:', params);
    return <DynamicInput {...params} />;
},
};

const DynamicTable = ({
  tableData,
  pinnedRows,
  visibleColumns,
  columnLabels = [],
  editableColumns = [],
  dynamicColumns = [],
  smallColumns = [],
  selectable,
  selectOnClick,
  draggable,
  updateTableData,
  removeable,
  actions = [],
  hasActionMenu = false,
  isCellEditable,
  itemName,
  rowCountError = false,
  hasNewSurvey = false,
  copiedSurvey,
  setCopiedSurvey,
}) => {
  const [copySurveySnackbarOpen, setCopySurveySnackbarOpen] = React.useState(false);
  const [copySuccessSnackbarOpen, setCopySuccessSnackbarOpen] = React.useState(false);
  const api = useApi();// backend api
  const apiRef = useGridApiRef();// data grid api
  const navigate = useNavigate();

  // create list of rows from the table data prop
  const rows = tableData.map((row, index) => {
    return { id: row.survey_id, ...row };
  });
  // removable / delete functionality
  if (Object.keys(actions).length > 0 && !visibleColumns.includes('actions')) {
    visibleColumns.push('actions');
  }

  const removeRow = (params, callback) => {
    const newRows = rows.filter(row => row.id !== params.row.id);
    newRows.length > 0 ? handleRowOrderChange(newRows) : updateTableData([]);
    callback(params);
  }

  const renderRegularCell = (params) => {
    let cellValue = params.value;
    if (params.field === 'created_at') {
      cellValue = new Date(params.value).toLocaleString();
    }
    // if the table has a list of dynamicColumns, check to see if this field is in that list
    // and if so, reveal the dynamicColumn list entry's DynamicComponent
    if (dynamicColumns.length > 0) {
      dynamicColumns.forEach((dynamicColumn) => {
        if (dynamicColumn.column === params.field) {
          cellValue = dynamicColumn.dynamicComponent(params.row);
        }
      });
    }
    return (
      <div className={`dynamic-table-cell ${params.field}`}>
        <>
          { params.field === 'name' && params.row.is_template && (
            <Chip
            icon={<Avatar src={logoBlueSmall} />}
            variant="outlined"
            label="Template"
            className="template-logo" />
          )}
          {cellValue}
        </>
      </div>
    );
  };

  const renderRemoveBtn = (params) => {
    return (
      <div className="dynamic-table-cell remove">
        <IconButton onClick={() => removeRow(params.row.id)} color="error">
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  const renderActionRow = (params) => {
    return (
      <div className="dynamic-table-cell actions">
        { actions.map((action, index) => {
          // determine if the action is disabled based on the row's status
          let display = 'flex';

          if (params.row.status) {
            // only run these checks on rows that have a status (surveys)
            if (action.label.includes('Pin')) {
              // pin action is always available
              display = 'flex';
            }
            if (action.label.includes('Delete') && params.row.is_template) {
              display = 'none';
            }
            if (params.row.status !== 'complete' && (action.label.includes('Results') || action.label.includes('Download'))) {
              // only complete surveys can show results
              display = 'none';
            }
            if ((params.row.status !== 'draft' && params.row.status !== 'error') && (action.label.includes('Edit') || action.label.includes('Details'))) {
              // only drafts and error status can be edited (and viewing details counts as an edit)
              display = 'none';
            }
            if (params.row.status === 'running' && !action.label.includes('Copy')) {
              // running surveys can only be copied
              display = 'none';
            }
            if (params.row.status === 'error' && (!action.label.includes('Copy') && !action.label.includes('Edit') && !action.label.includes('Delete'))) {
              // error surveys can only be viewed, edited, or deleted
              display = 'none';
            }
          } 
          return (
            <CustomTooltip title={action.label} key={index}>
              <IconButton
                alt={action.label}
                sx={{ display: display }}
                color={action.label.includes('Delete') ? 'error' : 'primary'}
                key={index} onClick={() => {
                  action.label.includes('Copy') && setCopySurveySnackbarOpen(true);
                  action.callback(params, apiRef);
                }}>
                {action.label.includes('Delete') && <DeleteOutlineOutlinedIcon />}
                {action.label.includes('Copy') && <ContentCopyIcon />}
                {action.label.includes('Edit') && <EditIcon />}
                {action.label.includes('Details') && <InfoIcon />}
                {action.label.includes('Download') && <DownloadIcon />}
                {action.label.includes('Results') && <BarChartIcon />}
                {action.label.includes('Pin') && params.row.is_pinned && <PushPinIcon />}
                {action.label.includes('Pin') && !params.row.is_pinned && <PushPinOutlinedIcon />}
              </IconButton>
            </CustomTooltip>
          );
        })}
      </div>
    );
  };

  // track the original row count to determine if a row has been added
  const [originalRowCount, setOriginalRowCount] = React.useState(rows.length);
  React.useEffect(() => {
    originalRowCount !==0 && rows.length > originalRowCount && copiedSurvey !== null && setCopySuccessSnackbarOpen(true);
    setOriginalRowCount(rows.length);
  }, [rows.length]);

  React.useEffect(() => {
    console.log('******* copiedSurvey', copiedSurvey);
  }, [copiedSurvey]);

  // create columns from visibleColumns prop and other args
  const columns = React.useRef(visibleColumns.map((column, index) => {
    let columnLabel = column.replaceAll('_', ' ');
    if (columnLabels.length > 0) {
      // override the parsed key with the pretty printed label
      columnLabel = columnLabels[index];
    }
    return {
      id: index,
      field: column,
      headerName: columnLabel,
      editable: editableColumns ? editableColumns.includes(column) : false,
      ...dynamicInputColumn,
      // only columns that are not in smallColumns should be highest flex value
      flex: smallColumns.includes(column) ? 0 : 1,
      sx: {
        width: column === 'actions' ? '250px !important' : 'auto',
        flexGrow: smallColumns.includes(column) ? 0 : 1,
        flexShrink: column === 'actions' ? 0 : 1,
      },
      renderHeader: () => (
        <div className={`dynamic-table-header ${columnLabel}`}>
          <strong>{columnLabel}</strong>
        </div>
      ),
      renderCell: (params) => {
          if (removeable && params.field === 'remove') {
            return renderRemoveBtn(params);
          } else if (actions.length > 0 && params.field === 'actions') {
            if (hasActionMenu) {
              // return renderActionMenu(params);
              return renderActionRow(params);
            } else {
              return renderActionRow(params);
            }
          } else {
            return renderRegularCell(params);
        }
      }
    }
  }));

  // drag and drop functionality (and sort-after-delete)
  const handleRowOrderChange = (data) => {
    let tempRows = [...rows];
    let hasSortOrder = false;

    if (Array.isArray(data)) {
      // rows can only be dragged one at a time and it passes an object,
      // so we know if we're passed an array it's been passed by removeRow,
      // and the array should just be the new table rows.
      hasSortOrder = data[0].hasOwnProperty('sort_order');
      tempRows = data;
    } else {
      // if we're passed an object, we need to update the table rows
      // and sort them based on the new order
      hasSortOrder = data.row.hasOwnProperty('sort_order');
      if (data.oldIndex < 0 || data.targetIndex < 0 || data.oldIndex === data.targetIndex) {
        return;
      } else if (data.targetIndex === tempRows.length - 1) {
        // moving the item to the end of the list
        tempRows.splice(data.oldIndex, 1);
        tempRows.push(data.row);
      } else {
        tempRows.splice(data.oldIndex, 1);
        tempRows.splice(data.targetIndex, 0, data.row);
      }
    }

    // now that the table is sorted, check for item sort_order and apply table index if found
    if (hasSortOrder) {
      tempRows = tempRows.map((row, index) => {
        return { ...row, sort_order: index + 1 };
      });
      updateTableData(tempRows);
    } else {
      updateTableData(tempRows);
    }
  }

  // editable cell functionality
  const processRowUpdate = (newRow) => {
    // TODO: fix for question parsing, needs refined
    // check to see if the "raw_options" key is present in the newRow object
    // and if so, update the "options" key with the value of "raw_options" split into a new array
    if (newRow.raw_options) {
      // check to see if the row was updated to question_type !== Single Choice or Multiple Choice, and if so, clear the options/raw options
      if (newRow.question_type !== 'single choice' && newRow.question_type !== 'multiple choice') {
        newRow.options = [];
        newRow.raw_options = '';
      } else {
        newRow.options = newRow.raw_options.split(',');
      }
    }
    const updatedRow = { ...newRow, isNew: false };
    updateTableData(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  // custom error state for no rows on attempted submit / next, we add a conditional "no rows" overlay
  const CustomNoRowsOverlay = () => {
    return (
      <div className="no-rows-overlay">
        {rowCountError ? <h4 style={{ color: 'red', margin: '20px 0', textAlign: 'center'}}>Error: You must add at least one {itemName}</h4> : <h4 style={{ margin: '20px 0', textAlign: 'center'}}>No {itemName}s added</h4>}
      </div>
    );
  };
  // const VIRTUALIZATION_CONFIG = {
  //   rowBuffer: 10,
  //   rowThreshold: 100,
  //   rowHeight: 52
  // };
  return (
    <Box className="dynamic-table-container">
        <Snackbar
        open={copySurveySnackbarOpen}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          setCopySurveySnackbarOpen(false);
        }}
        message="Copying Survey..."
        className={'copy-snackbar'}
        severity="info"
        variant="filled"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
        <Snackbar
        open={copySuccessSnackbarOpen}
        autoHideDuration={5000}
        onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setCopySuccessSnackbarOpen(false);
            setCopiedSurvey(null);
        }}
        className={'copy-success-snackbar'}
        severity="success"
        variant="filled"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <div>
            Survey Copied Successfully:&nbsp;
            <Link onClick={() => navigate(`/edit-survey/${copiedSurvey?.survey_id}`)}>
            {copiedSurvey?.name}
            </Link>
          </div>
        </Snackbar>
      {/* { removable && <Button onClick={() => removeRow()} variant="contained" startIcon={<DeleteIcon />} style={{ marginBottom: "8px" }} className="crowdwave-blue">Remove selected row</Button> } */}
      <DataGridPro
        rows={rows}
        pinnedRows={pinnedRows}
        columns={columns.current}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 50, 100, { value: -1, label: 'All' }]}
        apiRef={apiRef}
        loading={false}
        rowHeight={38}
        checkboxSelection={selectable}
        disableRowSelectionOnClick={selectOnClick}
        rowReordering={draggable}
        onRowOrderChange={handleRowOrderChange}
        sx={{ border: 'none' }}
        processRowUpdate={processRowUpdate}
        getRowHeight={() => 'auto'}
        isCellEditable={isCellEditable}
        onCellEditStop={(params, event) => {
          if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
            return;
          }
          if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
            event.defaultMuiPrevented = true;
          }
        }}
        autoHeight
        // virtualization
        // {...VIRTUALIZATION_CONFIG}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </Box>
  );
}

export default DynamicTable;