import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from '@mui/material';
import CustomTooltip from '../ui/CustomTooltip';
import DynamicCardList from '../dynamic/DynamicCardList';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

const Audiences = ({
  audienceData,
  hasAudienceData,
  isShowAudienceError,
  isShowAudienceSizeError,
  pageHasError,
  setPageHasError,
  surveyName,
  validateSurveyName,
  hasSurveyNameError,
  validateAudience,
  validateAudienceSize,
  segmentData,
  setSegmentData,
  segmentSizeLimit,
  segmentDeleteCallback,
  segmentCharLimit,
  segmentCountLimit,
  hasCustomSplit,
  setHasCustomSplit,
  segmentsWithErrors,
  segmentSuggestions
}) => {
  const emptySegment = {
    id: 0,
    audience_id: '',
    segment_name: '',
    segment_size: hasCustomSplit ? null : Math.floor(audienceData[0].audience_size / (segmentData.length + 1)),
    audience_segment_id: '',
    segment_chips: [],
    has_name_error: false,
    has_size_error: false,
    sort_order: 1
  };
  const audienceSizeHelperText = () => {
    let helperText = '';
    if (isShowAudienceSizeError) {
      helperText = 'Please enter a number from 1-1000';
    } else if (hasCustomSplit) {
      helperText = 'Audience Size is ignored while Custom Distribution is selected, please enter segment sizes manually below';
    }
    return helperText;
  }

  return (
    <Grid container>
      <Grid xs={12} md={12} lg={12} >
        <Container className="left-panel">
            <Box className="textBox" sx={{ mt: 2 }}>
              <Box className="textField-box">
                <TextField
                  id="survey-name"
                  label="Survey Name"
                  data-test-id="survey-name"
                  fullWidth
                  error={hasSurveyNameError}
                  helperText={hasSurveyNameError ? 'Please add a survey name' : 'Survey names should be unique and do not affect results'}
                  name='surveyName'
                  value={surveyName}
                  className={hasSurveyNameError ? 'has-error' : ''}
                  onChange={(e) => validateSurveyName(e.target.value)}
                  />
              </Box>
            </Box>
            <h2>
              Audience and Segments
            </h2>
            <p>
              Define any group you want to target as your main audience.
              You can be specific or general depending on your objective.
              For example, "Soda drinkers in America" or "Car enthusiasts in California."
            </p>
            <Box className="textBox" sx={{ mt: 2 }}>
            <Box className="dynamic-card-field">
                <TextField
                    id={`audience-size-input`}
                    className={`audience-size-input`}
                    label="Audience Size"
                    data-test-id={`audience-size-input`}
                    type="number"
                    error={isShowAudienceSizeError}
                    helperText={audienceSizeHelperText()}
                    value={audienceData[0].audience_size || 0}
                    sx={{ width: '175px' }}
                    disabled={hasCustomSplit ? true : false}
                    // onFocus={(e) => {
                    //     let audienceSize = e.target.value;
                    //     audienceSize === 0 ? audienceSize = '' : audienceSize = audienceSize;
                    // }}
                    onChange={(e) => validateAudienceSize(e.target.value)}/>
            </Box>
              <Box className="dynamic-card-field">
              <TextField
                  id="audience"
                  label="Audience"
                  data-test-id="audience"
                  fullWidth
                  error={isShowAudienceError}
                  helperText={isShowAudienceError ? 'Please add an audience up to 1000 characters in length' : ''}
                  value={audienceData[0].audience}
                  className={isShowAudienceError && !hasAudienceData ? 'has-error' : ''}
                  onChange={(e) => validateAudience(e.target.value)}
                  />
              </Box>
            </Box>
          <h2 style={{ fontSize : '20px' }}>
            Audience Segmentation (Optional)
            {/* <CustomTooltip placement="right"
              title={
                  <React.Fragment>
                  </React.Fragment>
              }>
              <em className="info">i</em>
            </CustomTooltip> */}
          </h2>
          <p>
            Defining segments within your audience will help narrow in on specific groups you want to target for outreach.
            Segment descriptors can be based on things like purchase or use frequency, demographics, psychographics, behaviors,
            or unique preferences.
          </p>
          {segmentData.length > 0 && (
           <Box>
            <p>
            We recommend making your segment traits as specific as possible to get the most accurate results, but you can use
            as many traits as you need to describe your segment, e.g. "drinks soda 1+ times a week" and "owns a car" and "lives in California."
            </p>
            <p>
              By default, your audience size will be split evenly amongst your segments. To disable this, you can select "Custom Distribution"
              and manually enter your segment sizes.
            </p>
            <Switch
              inputProps={{
                'aria-label': 'Custom Distribution',
                'onKeyDown': (e) => {
                  if (e.key === 'Enter') {
                    setHasCustomSplit(!hasCustomSplit);
                  }
              }}}
              onChange={(e) => {
                console.log('switch:', e.target.checked);
                setHasCustomSplit(e.target.checked);
                // if any segments exist in segmentData, set their segment_size to the segment split
                if (e.target.checked) {
                  const segmentSplit = Math.floor(audienceData[0].audience_size / segmentData.length);
                  const updatedSegments = segmentData.map((segment) => {
                    return {
                      ...segment,
                      segment_size: segmentSplit
                    };
                  });
                  setSegmentData(updatedSegments);
                }
              }}
              value={hasCustomSplit}/> Custom Distribution {hasCustomSplit ? '(Enabled)' : '(Disabled)'}
           </Box>
          )}
          <Grid item xs={12} md={10} lg={10}>
          <DynamicCardList
            label={'segment'}
            emptyItem={emptySegment}
            listData={segmentData}
            setListData={setSegmentData}
            itemDeleteCallback={segmentDeleteCallback}
            segmentSizeLimit={segmentSizeLimit}
            itemsWithErrors={segmentsWithErrors}
            audienceSize={audienceData[0].audience_size}
            hasCustomSplit={hasCustomSplit}
            pageHasError={pageHasError}
            setPageHasError={setPageHasError}
            allowEmpty={true}// segments are optional
            isSortable={true}
            segmentSuggestions={segmentSuggestions}
            segmentCharLimit={segmentCharLimit}
            segmentCountLimit={segmentCountLimit}/>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default Audiences;