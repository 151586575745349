import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { useApi } from '../../AuthProvider';

const WhatsNewModal = ({isWhatsNewModalOpen = false, setIsWhatsNewModalOpen, user }) => {
    const navigate = useNavigate();
    const api = useApi();

    React.useEffect(() => {
        if (window.location.pathname === '/' && user !== null && user !== undefined && user?.seen_latest_features === false) {
            //TODO this doesn't seem efficient
            setIsWhatsNewModalOpen(true);
        }
    }, [window.location.pathname, user]);

    return (
        <React.Fragment>
            <Dialog
                open={isWhatsNewModalOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{maxWidth:'900px', margin:'auto'}}
            >
                <DialogTitle id="alert-dialog-title">
                    <h3>{"Recent Updates"}</h3>
                </DialogTitle>
                <DialogContent className="whats-new-content">
                    <section>
                        <h3>Dec 27, 2024</h3>
                        <ul>
                            <li>
                                <strong>Feature Update - Flexible Audience Segmentation:</strong>&nbsp;We've updated our audience segmentation feature to give you more control. Segmentation is now optional rather than required, allowing you to launch surveys faster when targeting a broad audience. The number of respondents for your survey can now be set at the audience level and you can run your survey with just your audience. For those who still need more detailed insights, the segmentation option remains fully available. Segment size can be automatically split for you accounting for equal segmentation distribution or customized to your needs.
                            </li>
                            <li>
                                <strong>Improved Survey Results Page:</strong>&nbsp;We've enhanced the survey results page to provide a clearer and more insightful experience. Survey details including audience, any segments and all questions are now displayed above your results, and we've made design updates to improve readability.
                            </li>
                            <li>
                                <strong>Improved Results Page Chart Design:</strong>&nbsp;Charts now display with stacked color-coded results making it easier to understand your data at a glance. If you segment your audience, you can now compare segments within each chart enabling you to quickly zero in on key differences. Understanding your data just got even easier!
                            </li>
                        </ul>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={async () => {
                        if (!user?.seen_latest_features) {
                            // flip the seen_latest_features flag until the next release
                            const featuresResponse = await api.post(`/users/seen-latest-features`);
                            if (featuresResponse && featuresResponse.status !== 200) {
                                console.error('Error changing seen_latest_features');
                            }
                        }
                        setIsWhatsNewModalOpen(false);
                        }}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default WhatsNewModal;