import * as React from 'react';
import { BarChartPro as BarChartPro } from '@mui/x-charts-pro/BarChartPro';

const BarChartStacked = ({ labels, stackedSeries, type}) => {
  const primaryColor = '#0D49A0';
  // create list of truncated labels
  const truncatedLabels = labels.map((label) => {
    return label.length > 40 ? label.substring(0, 40) + '...' : label;
  });
  const fullColorPaletteCategorical = [
    '#226ff5',
    '#a788fc',
    '#35CA98',
    '#9AE6F7',
    '#FA9625',
    '#E21F60',
    '#FCD80D',
    '#2E22DA',
    '#88F414',
    '#417C89',
    '#DA62BA',
    '#8100C1'
  ];
  // full = 16
  const fullColorPalletteSequential = [
    '#CCF6FF',
    '#BCEBF8',
    '#ACE0F1',
    '#9DD5EA',
    '#8DCAE4',
    '#7EBEDD',
    '#6EB2D7',
    '#5EA5D0',
    '#4F98CA',
    '#3F8AC3',
    '#2E7BBC',
    '#1C6BB4',
    '#085AAD',
    '#0047A5',
    '#002F9F',
    '#0B009E'
  ];

  const calculatedColorPalette = (stackedSeries) => {
    let calculatedPalette = [];
    let options = stackedSeries;
    if (type === 'numeric rating') {
      calculatedPalette = fullColorPalletteSequential;
      // if the list of labels is less than the full color palette, try to split the palette
      // evenly across the labels to increase contrast between label colors
      if (options.length < fullColorPalletteSequential.length) {
        let paletteRatio = Math.floor(fullColorPalletteSequential.length / options.length);
        calculatedPalette = [];
        for (let i = 0; i < fullColorPalletteSequential.length; i++) {
          if (i % paletteRatio === 0) {
            calculatedPalette.push(fullColorPalletteSequential[i]);
          }
        }
      }
    } else {
      calculatedPalette = fullColorPaletteCategorical;
    }
    return calculatedPalette;
  }


  const longestLabel = truncatedLabels.reduce((a, b) => a.length > b.length ? a : b);

  const calcLegendHeight = (stackedSeries) => {
    let legendHeight = 0;// eventual height of the legend used for chart's margin.top
    let legendWidth = 0;// width of all the options characters horizontally, plus the legend "squares" +/- margins
    let seriesCount = stackedSeries.length;// number of options
    let totalChars = 0;// total number of characters in all the options
    stackedSeries.forEach((series) => {
        totalChars += series.label.length;
    });
    // legend cubes are 20px wide, text is 16px wide, add 10% for good measure
    legendWidth = ((seriesCount * 20) + (totalChars * 16));
    // get the current viewport width and knock it down to the 80%-ish we have to play around with
    const viewportWidth = window.innerWidth * .8;
    // divide the legendWidth by the viewportWidth to get the number of lines and multiply by line height (roughly)
    legendHeight = Math.ceil(legendWidth / viewportWidth) * 30;

    return legendHeight < 50 ? 50 : legendHeight;
  }

  const isMultiChoice = type === 'multiple choice';

  const calcChartHeight = (stackedSeries) => {
    let chartHeight = 0;

    let segmentCount = stackedSeries[0].data.length;
    let legendHeight = calcLegendHeight(stackedSeries);
    chartHeight = (segmentCount * 30) + legendHeight + 75;
    if (isMultiChoice) {
      // add height incrementally for each series
      // chartHeight = chartHeight + (stackedSeries.length * 30);
      chartHeight = chartHeight + (stackedSeries.length * stackedSeries[0].data.length * 5);
    }
    return chartHeight;
  }

  return (
    <BarChartPro
      className='bar-chart-pro-container'
      height={calcChartHeight(stackedSeries)}
      series={stackedSeries}
      layout={'horizontal'}
      colors={calculatedColorPalette(stackedSeries)}
      xs={12} md={12} lg={8} tabIndex={0}
      yAxis={[{
        scaleType: 'band',
        data: labels,
        valueFormatter: (value, context) => {
          return context.location === 'tick' ? truncatedLabels[labels.indexOf(value)] : value;
        }
      }]}
      xAxis={[{
        tickInterval: [10,20,30,40,50,60,70,80,90,100],
        tickMinStep: 1,
        min: 0,
        max: 100,
        valueFormatter: (value) => `${value}%`,
      }]}
      borderRadius={5}
      margin={{
        left: parseInt(longestLabel.length * 2.5),
        top: calcLegendHeight(stackedSeries)
      }}
      style={{
        'overflow': 'visible'// override (style = inline, sx = appended CSS prop)
      }}
      slotProps={{
        legend: {
          position: {vertical: 'top', horizontal: 'middle'},
        },
      }}
    />
  );
}

export default BarChartStacked;